import { Button, Modal, Tooltip, Switch } from "antd";
import React, { useState } from "react";
import { withWindowSizeHOC } from "../../../Common/withWindowSizeHOC";
import { connect } from "react-redux";
import { toggleLeadAutomationFlag } from "../../../reducersAndActions/Actions";
function SelectSampleVariantModal({
  showSampleOptions,
  handleResetUrlsModalCancel,
  generatePdf,
  downloadloader,
  leadAutomation,
  toggleLeadAutomationFlag,
}) {
  const [v1Sample, setV1Sample] = useState(false);
  return (
    <Modal
      title="Please select a sample variant"
      visible={showSampleOptions}
      onCancel={handleResetUrlsModalCancel}
      centered
      footer={[
        <Button key="cancel" onClick={handleResetUrlsModalCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={() => {
            if (v1Sample) {
              generatePdf("v1Sample");
            } else {
              generatePdf("Sample");
            }
          }}
          loading={downloadloader}>
          Generate {v1Sample ? "V1" : "V2"}
        </Button>,
      ]}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}>
        <div>
          {v1Sample
            ? "V1 Sample is selected. This will generate a PDF with V1 Sample data."
            : "V2 Sample is selected. Please make sure you have added blur pages for this report."}
        </div>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}>
          <div
            title={`Toggle to${leadAutomation ? " not" : ""} send it to Lead Automation.`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              padding: "3px 5px",
              marginRight: "8px",
            }}>
            <p
              style={{
                width: "max-content",
              }}>
              Lead Automation
            </p>
            <Tooltip>
              <Switch
                checked={leadAutomation}
                onChange={() => {
                  toggleLeadAutomationFlag(!leadAutomation);
                }}
              />
            </Tooltip>
          </div>
          <p>{v1Sample ? "V1 Sample" : "V2 Sample"}</p>
          <Tooltip title="tap to switch between V1 and V2 Sample">
            <Switch
              checked={v1Sample}
              onChange={() => {
                setV1Sample(!v1Sample);
              }}
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
}

// export default SelectSampleVariantModal;

function mapStateToPros(state) {
  return {
    leadAutomation: state.leadAutomation,
  };
}

const mapDispatchToProps = {
  toggleLeadAutomationFlag,
};

export default withWindowSizeHOC(
  connect(mapStateToPros, mapDispatchToProps)(SelectSampleVariantModal)
);
