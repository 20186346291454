import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
// import BoldTextTool from "../Common/RteCustomFunctions/BoldTextTool";

import { EDITOR_JS_TOOLS } from "../../../constants";
import { notification } from "antd";

const EDITTOR_HOLDER_ID = "analysis-editor";

const CustomAnalysis = (props) => {
  const ejInstance = useRef();
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    if (!ejInstance.current) {
      checkAnalysisLimit(
        props?.trends?.analysis?.map((item) => item.text) || []
      );
      ejInstance.current = new EditorJS({
        data: {
          time: new Date().getTime(),
          blocks: [
            {
              type: "list",
              data: {
                style: "unordered",
                items:
                  props.trends.analysis && props.trends.analysis.length
                    ? props.trends.analysis.map((item) => item.text)
                    : [],
              },
            },
          ],
        },
        holder: EDITTOR_HOLDER_ID,
        autofocus: true,
        tools: EDITOR_JS_TOOLS,
        onChange: handleSave,
      });
    } else {
      if (ejInstance && ejInstance.current && ejInstance.current.isReady) {
        checkAnalysisLimit(
          props?.trends?.analysis?.map((item) => item.text) || []
        );
        ejInstance.current.isReady
          .then(() => {
            ejInstance.current.clear();
            ejInstance.current.blocks.render({
              blocks: [
                {
                  type: "list",
                  data: {
                    style: "unordered",
                    items:
                      props.trends.analysis && props.trends.analysis.length
                        ? props.trends.analysis.map((item) => item.text)
                        : [],
                  },
                },
              ],
              holder: EDITTOR_HOLDER_ID,
              inlineToolbar: ["link", "marker", "bold", "italic"],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, []);

  async function handleSave() {
    let countBrTags = 0;
    let countNBSPTags = 0;

    ejInstance.current
      .save()
      .then((outputData) => {
        let mergedArray = outputData.blocks
          .map((item) => item.data.items)
          .flat(Infinity);
        let checkLimit = checkAnalysisLimit(mergedArray);

        if (checkLimit instanceof Error) {
          notification.open({
            type: "error",
            message: checkLimit?.message || "Something went wrong",
          });
          return;
        }
        let finalAnalysis = [];
        for (let i = 0; i < mergedArray.length; i++) {
          finalAnalysis.push({
            text: mergedArray[i],
            count: mergedArray[i].length,
            hyperLink: "",
          });
          props.setTempConfig((p) => {
            return {
              ...p,
              analysis: {
                ...p.analysis,
                data: { ...p.analysis.data, analysis: finalAnalysis },
              },
            };
          });
          countNBSPTags += (mergedArray[i].match(/&nbsp;/g) || []).length;
          countBrTags += (mergedArray[i].match(/<br>/g) || []).length;
        }
        countNBSPTags = countNBSPTags * 5;
        countBrTags = countBrTags * 4;

        let result = mergedArray
          .filter(Boolean)
          .reduce((sum, ele) => sum + ele.length, 0);

        result = result > 0 ? result - countNBSPTags : result;
        result = result > 0 ? result - countBrTags : result;

        // props.setAnalysisCharCount(result);
        // // Show char count error to everyone except QC designation
        // if (
        //   props.USER_DATA.obj.designation !== "QC" &&
        //   result > props.character_count
        // ) {
        //   props.setCharacterCountError(true);
        //   if (!skip) {
        //     message.destroy();
        //     message.warning({
        //       message: "Info",
        //       content: (
        //         <div>
        //           <InfoCircleOutlined />
        //           Character count is more than {props.character_count}
        //         </div>
        //       ),
        //       icon: (
        //         <Button onClick={handleSkip} type="primary">
        //           Don't show again
        //         </Button>
        //       ),
        //       duration: 5,
        //     });
        //   }
        // } else {
        //   props.setCharacterCountError(false);
        // }

        //  if(mergedArray && mergedArray.length === 0){
        //   if(props?.draft_data_analysis && props.draft_data_analysis?.length ){
        //     mergedArray = [...props.draft_data_analysis]
        //    }
        //  }

        // props.setAnalysisEditorState(mergedArray);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  }

  function checkAnalysisLimit(analysisArr) {
    try {
      // if (!analysisArr?.length) {
      //   throw new Error("Analysis is empty");
      // }

      let finalStr = "";
      analysisArr.forEach((element) => {
        finalStr += element;
      });
      props.setLimitConsumed(finalStr.length);

      if (finalStr.length > props.limit) {
        throw new Error("Analysis limit exceeded");
      }
    } catch (error) {
      return error;
    }
  }

  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        height: "500px",
        overflow: "hidden",
        border: "1px solid #0000002b",
        marginTop: "10px",
        borderRadius: "10px",
      }}>
      <React.Fragment>
        <div id={EDITTOR_HOLDER_ID}> </div>
      </React.Fragment>
    </div>
  );
};

export default CustomAnalysis;

// export default CustomAnalysis;
