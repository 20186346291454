import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withWindowSizeHOC } from "../Common/withWindowSizeHOC";
import { InterFaceStyleComponent } from "../styles/Interface.style";
import MaskedComponent from "./MaskComponent";
import CompanyProfileContainer from "../pages/CompanyProfileContainer";
import {
  Drawer,
  Button,
  Modal,
  Menu,
  Spin,
  notification,
  Space,
  Table,
  Tag,
  Input,
} from "antd";
import {
  ReloadOutlined,
  EditOutlined,
  ClockCircleOutlined,
  MenuUnfoldOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  setTocData,
  setTocRawData,
  setSelectedCustomSlideData,
  setShowGraphVal,
  setDraftStage,
  setNoDraftsFound,
  setSpecificDraftLoading,
  setDraftDrawer,
  setPublishedDrawer,
  setDraftVersions,
  setDefaultDraftStageFromGetSpecificDataAndDraftVersions,
  setDraftDataAnalysis,
  setDraftDataSources,
  setActiveTab,
  setSwitchDisable,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setCacheId,
  setTemplateType,
  setDraftId,
  setDraftDataHeading,
  setInitialDraftVersionsLoading,
  setIndicatorDropdownOptions,
  setCompanyProfileData,
  setCompanyProfileDimensions,
  setCompanyType,
  setCharacterCountError,
  setExecSummaryData,
  getSpecificDraftApi,
  getSpecificPublishedVersionApi,
  partialStateReset,
  setDimensions,
  setGraphColumn,
  setGraphData,
  setEditGraph,
  setEditGraphConfig,
} from "../reducersAndActions/Actions";
import {
  axios,
  config,
  Template1100,
  Template1111,
  Template1000,
  Template1001,
  Template1011,
  Template1101R,
  Template1101C,
  Home,
  Contact,
  Disclaimer,
  ReportOffer,
  StudyAssumption,
  ScopeOfStudyStatic,
  KeyStrategicThemesCEOS,
  ResearchMethodology,
  DecisionMaker,
  SegmentDefination,
  FeedbackAndAudit,
  Sources,
  ScopeStudyRegional,
  ProductAndServices,
  GrowthStrategyMatrix,
  GlobalOverview,
  RecentDevelopment,
  EditableExecutiveSummary,
  StaticExecutiveSummary,
  FeedProduction,
  CompanyProfile,
  RegulatoryFramework,
  RegionOverview,
  ListOfFigures,
  Introduction,
  RegulatoryFrameworkV2,
  ValueChainHubs,
  PrimaryInsights,
  CompetitiveLandscape,
  StaticSlide,
  IndicatorsOne,
  IndicatorsTwo,
  IndicatorsThree,
  IndicatorsFour,

} from "./index";
import CompanyList from "../pages/CompanyList";
import ConsumerBuying from "../pages/ValueChain/Confectionery/consumer_buying/index";
import xlsx from "json-as-xlsx";
import GraphConfig from "./GraphConfig";

var Base64 = require("js-base64").Base64;
let URL = config.api.base_url;
const { SubMenu } = Menu;

const componentMapping = {
  home: Home,
  disclaimer: Disclaimer,
  introduction: Introduction,
  scopeOfStudy: ScopeStudyRegional,
  scopeofstudystatic: ScopeOfStudyStatic,
  reportoffers: ReportOffer,
  studyAssumptions: StudyAssumption,
  researchMethodology: ResearchMethodology,
  feedproduction: FeedProduction,
  regulatoryFramework: RegulatoryFrameworkV2,
  regulatoryFramework2: RegulatoryFramework,
  decisionMakerAnalysis: DecisionMaker,
  valueChain: ValueChainHubs,
  globaloverview: GlobalOverview,
  regionoverview: RegionOverview,
  recentdevelopment: RecentDevelopment,
  companyProfiles: CompanyProfile,
  companyProfile2: CompanyProfileContainer,
  companyProfile1: CompanyProfileContainer,
  company_list: CompanyList,
  growthstrategymatrics: GrowthStrategyMatrix,
  keyStrategicThemes: KeyStrategicThemesCEOS,
  appendix1: SegmentDefination,
  appendix2: ProductAndServices,
  competitive_landscape_overview: CompetitiveLandscape,
  sources: Sources,
  listOfFighure: ListOfFigures,
  feedbackandaudit: FeedbackAndAudit,
  contact: Contact,
  mask: MaskedComponent,
  primary_insights: PrimaryInsights,
  executive_summary: EditableExecutiveSummary,
  executive_summary_static: StaticExecutiveSummary,
  static_slide: StaticSlide,
  1100: Template1100,
  1000: Template1000,
  1111: Template1111,
  1011: Template1011,
  1001: Template1001,
  "1101r": Template1101R,
  "1101c": Template1101C,
  consumer_buying: ConsumerBuying,
  indicatorSoftOne: IndicatorsOne,
  indicatorSoftTwo: IndicatorsTwo,
  indicatorSoftThree: IndicatorsThree,
  indicatorSoftFour: IndicatorsFour,
};

class Interface extends Component {
  state = {
    data: this.props.data ? this.props.data : [],
    relationArray: [],
    initialDefaultSlide: false,
    slide_templates: ["executive_summary"],
    drawer: false,
    activetab: false,
    loading: true,
    fromInterface: true,
    slideData: [],
    template_slide_name: "",
    expandedKeys: [],
    slectedTocElement: null,
    defaultSelectedTocElement: [],
    openKeys: [],
    subArrayKeys: [],
    fetch_specific_draft_warning: false,
    fetch_specific_published_warning: false,
    published_access_warning: false,
    drafts_access_warning: false,
    drafts_different_user_warning: false,
    draft_versions_email_id: "",
    delete_graph_data_modal: false,
    selected_graph_id: null,
    waterfall_graph_column: [],
    waterfall_graph_data: [],
    delete_graph_type: null,
    indicator_graph_data: [],
    selectedIndicator: null,
    selectedCustomSlideData: {},
  };

  componentDidMount() {
    this.props.handleSwitchchange(false);
    let tocDimensions;
    tocDimensions = this.props.TocApiParams;
    let new_toc_obj;
    let overview_element;
    let payload = {
      hub_id: tocDimensions.hub_id,
      dimensions: tocDimensions.dimensions,
      meta: {
        reports: true,
        sample: false,
        interface: true,
      },
    };
    let currentSlug = window.location.pathname.replace("/editing/report/", "");
    if (currentSlug != tocDimensions.slug) {
      axios
        .get(`${URL}/api/searchReportBySlug`, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            slug: currentSlug,
            hub_id: tocDimensions.hub_id,
          },
        })
        .then((response) => {
          if (response.data.slug) {
            this.props.setTocData(response.data);

            window.location.reload();
          }
        })
        .catch((err) => {
          console.log("interface err", err);
        });
    }

    axios
      .post(`${URL}/api/generateToc`, payload)
      .then((response) => {
        if (response.status === 200) {
          new_toc_obj = [...response.data];
          this.props.setTocRawData(new_toc_obj);
          overview_element = new_toc_obj.map((item, i) => {
            if (
              item.children &&
              item.children.length &&
              item.title === "Market Segmentation"
            ) {
              this.AddOverviewSlideToToc(item);
            }
          });

          this.getSubArrayKeysmethod(new_toc_obj);
          // create a array of titles and section_id and children
          let relationArray = this.createRelationArray(new_toc_obj);
          this.setState({
            data: new_toc_obj,
            relationArray,
          });
          let p = this.props.location.pathname.split("@");
          let ids = p[1] ? p[1] : null;
          let url = p[0];
          if (ids) {
            let findKeys = ids.slice(1).split("/");
            findKeys = findKeys.map((item) => item.trim());
            // let findKeys = ["Market Segmentation", "By Hotspot", "Overview"];

            //open the sidebar
            let keys = [];
            this.findAndOpenitem(this.state.relationArray, findKeys, keys);
            this.setState({
              openKeys: keys,
            });
          }

          // if extra path does not exist in the url
          if (!ids) {
            let initialSlideParams = new_toc_obj.filter((item, i) => {
              return item.title === "Executive Summary & Key Findings";
            });

            this.getdefaultSlideData(
              initialSlideParams[0],
              initialSlideParams[0].hub_id,
              initialSlideParams[0].meta.template
            );
          }
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  ///@/Market Segmentation/By Data Center Size/Overview
  componentDidUpdate(prevProps, prevState) {
    if (this.props.ACTIVE_TAB !== prevProps.ACTIVE_TAB) {
      //Call Get Specific Data API On Tab Change From Drafts To Published Or Vice Versa
      this.changeslide(null, this.state.slide_details, this.state.hub_id);
      this.props.setSwitchDisable(false);
    }

    if (
      this.props.edit_mode !== prevProps.edit_mode ||
      this.props.selected_indicator_heading !==
        prevProps.selected_indicator_heading
    ) {
      if (
        this.props.edit_mode &&
        this.props.ACTIVE_TAB === "drafts" &&
        ![
          "companyProfile",
          "companyProfile1",
          "companyProfile2",
          "companyProfile3",
        ].includes(this.props.template_type)
      ) {
        this.props.setInitialDraftVersionsLoading(true);
        //Parent_id Creation API Call In Drafts Tab

        if (!this.props.parent_id) {
          axios({
            method: "POST",
            url: `${config.api.base_url}/api/editing/parent_doc`,
            data: {
              type: this.props.template_type,
              obj: {
                hub_id: this.state.slide_details.hub_id,
                dimensions: this.state.slide_details.dimensions,
                meta: {
                  typeValue: this.state.slide_details.meta.hasOwnProperty(
                    "typeValue"
                  )
                    ? this.state.slide_details.meta.typeValue
                    : "",
                  indicator_type: this.state.slide_details.meta.hasOwnProperty(
                    "indicator_type"
                  )
                    ? this.state.slide_details.meta.indicator_type
                    : "",
                },
              },
              cacheId: this.props.cache_id,
            },
          })
            .then((response) => {
              this.props.setParentId(response.data.parent_id);
              this.getDraftVersionsAndFetchFirstDraft();
            })
            .catch((error) => {
              this.props.setInitialDraftVersionsLoading(false);
              console.log(error);
            });
        } else {
          this.getDraftVersionsAndFetchFirstDraft();
        }
      }

      if (
        this.props.edit_mode &&
        this.props.template_type.includes("companyProfile")
      ) {
        this.companyProfileApi();
      }

      if (
        this.props.edit_mode &&
        this.props.ACTIVE_TAB === "published" &&
        ![
          "companyProfile",
          "companyProfile1",
          "companyProfile2",
          "companyProfile3",
        ].includes(this.props.template_type)
      ) {
        if (
          this.props.USER_DATA &&
          this.props.USER_DATA.obj &&
          this.props.USER_DATA.obj.access &&
          this.props.USER_DATA.obj.access.edit &&
          !this.props.USER_DATA.obj.access.edit.includes("PUBLISHED")
        ) {
          this.setState({
            published_access_warning: true,
          });
          this.props.setSwitchDisable(true);
          this.props.handleSwitchchange(false);
        }

        //Parent_id Creation API Call In Published Tab
        if (!this.props.parent_id) {
          axios({
            method: "POST",
            url: `${config.api.base_url}/api/editing/parent_doc`,
            data: {
              type: this.props.template_type,
              obj: {
                hub_id: this.state.slide_details.hub_id,
                dimensions: this.state.slide_details.dimensions,
                meta: {
                  typeValue: this.state.slide_details.meta.hasOwnProperty(
                    "typeValue"
                  )
                    ? this.state.slide_details.meta.typeValue
                    : "",
                  indicator_type: this.state.slide_details.meta.hasOwnProperty(
                    "indicator_type"
                  )
                    ? this.state.slide_details.meta.indicator_type
                    : "",
                },
              },
              cacheId: this.props.cache_id,
            },
          })
            .then((response) => {
              this.props.setParentId(response.data.parent_id);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }

    if (this.props.edit_mode !== prevProps.edit_mode && !this.props.edit_mode) {
      // WE DON'T WANT TO CLEAR ALL THE REDIS CACHE BUT ONLY THE SPECIFIC CACHE ID FOR CHOSEN COMPANY IN COMPANY PROFILE EDIT
      this.changeslide(null, this.state.slide_details, this.state.hub_id);
    }

    //Only For Company Profiles, We Have Different Types Of Companies - marketCompany, globalCompany etc
    if (
      this.props.companyType !== prevProps.companyType ||
      this.props.update_company_profile_data !==
        prevProps.update_company_profile_data
    ) {
      if (this.props.edit_mode) {
        this.companyProfileApi();
      }
    }

    //Checkbox Toggle For Company Profiles recentDevelopment And productServices
    if (this.props.fetch_full_data !== prevProps.fetch_full_data) {
      this.companyProfileApi();
    }

    this.getGraphData(this.props.template_type);
    if (this.props.template_type == "typedetail") {
      this.getGraphData("waterfall");
    }
  }

  formatGraphData = (rawData = []) => {
    let data = [];
    rawData.forEach((item, key) => {
      // eslint-disable-next-line
      Object.entries(item)?.length &&
        Object.entries(item)?.forEach((arr) => {
          if (arr[0] === "dimensions") {
            // eslint-disable-next-line
            Object.entries(arr[1])?.length &&
              Object.entries(arr[1])?.map((dimension) => {
                data[key] = {
                  ...data[key],
                  key,
                  [`${dimension[0].split("_").join(" ")}`]: dimension[1],
                };
              });
          } else if (arr[0] === "analysis" && arr[1]?.length) {
            arr[1]?.length &&
              arr[1].forEach((analysis, index) => {
                data[key] = {
                  ...data[key],
                  key,
                  [`analysis ${index + 1}`]: analysis,
                };
              });
            return;
          } else if (arr[0] === "data") {
            // if (["value", "volume"].includes(arr[1])) {
            // eslint-disable-next-line
            Object.entries(arr[1])?.length &&
              Object.entries(arr[1])?.forEach((type) => {
                data[key] = {
                  ...data[key],
                  key,
                  [`${type[0]}Unit`]: type[1].unit,
                };

                data[key] = {
                  ...data[key],
                  key,
                  [`${type[0]}Metric`]: type[0],
                };

                // loop for year for both value and volume
                // eslint-disable-next-line
                Object.entries(type[1]?.data)?.length &&
                  Object.entries(type[1]?.data)?.map((year) => {
                    data[key] = {
                      ...data[key],
                      key,
                      [`${type[0]}${year[0]}`]: year[1],
                    };
                  });
              });
            // }
          } else if (arr[0] === "source" && arr[1]?.length) {
            arr[1]?.length &&
              arr[1].forEach((source, index) => {
                data[key] = {
                  ...data[key],
                  key,
                  [`source ${index + 1}`]: source,
                };
                //
              });
            return;
          } else {
            data[key] = {
              ...data[key],
              key,
              [`${arr[0]}`]: arr[1],
            };
          }
        });
    });
    return data;
  };

  getGraphData = (template) => {
    if (
      ["typeoverview", "typedetail", "waterfall", "indicator"].includes(
        template
      ) &&
      this.props.edit_graph &&
      !this.props.edit_graph_column.length &&
      !this.props.edit_graph_data.length &&
      !this.state.indicator_graph_data.length &&
      (this.state.slide_details?.meta?.typeValue ||
        this.state.slide_details?.meta?.indicator_type)
    ) {
      const typeValue =
        template == "indicator"
          ? this.state.slide_details?.meta?.indicator_type
          : this.state.slide_details?.meta?.typeValue;
      let payload = {
        dimensions: this.state.slide_details?.dimensions || {},
        typeValue,
        template,
      };

      if (
        ["waterfall", "indicator"].includes(template) &&
        !this.props?.TOC_DATA?.report_id
      ) {
        notification.error({
          type: "error",
          message: "Report Id missing. Contact Tech Team.",
        });
        return;
      } else if (
        ["waterfall", "indicator"].includes(template) &&
        this.props?.TOC_DATA?.report_id
      ) {
        payload["report_id"] = this.props?.TOC_DATA?.report_id;
      }

      axios
        .post(`${URL}/api/editing/graph-data`, payload)
        .then((response) => {
          if (response.status === 200) {
            let columns = [];
            let data = [];
            let indicatorData = [];
            if (template == "indicator") {
              this.setState({ indicator_graph_data: response?.data?.data });
            }

            // eslint-disable-next-line
            response?.data?.data
              ?.flatMap((array) => array)
              ?.forEach((item, key) => {
                // eslint-disable-next-line
                Object.entries(item)?.length &&
                  Object.entries(item)?.forEach((arr) => {
                    if (arr[0] === "dimensions") {
                      // eslint-disable-next-line
                      Object.entries(arr[1])?.length &&
                        Object.entries(arr[1])?.map((dimension) => {
                          if (
                            !columns.some(
                              (obj) =>
                                obj.key ===
                                `${dimension[0].split("_").join(" ")}`
                            )
                          ) {
                            columns.unshift({
                              title: `${dimension[0].split("_").join(" ")} `,
                              dataIndex: `${dimension[0].split("_").join(" ")}`,
                              key: `${dimension[0].split("_").join(" ")}`,
                              render: (text) => (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    width: "max-content",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textTransform: "capitalize",
                                  }}
                                  title={text}
                                >
                                  {text}
                                </p>
                              ),
                            });
                          }
                          data[key] = {
                            ...data[key],
                            key,
                            [`${dimension[0].split("_").join(" ")}`]:
                              dimension[1],
                          };
                        });
                    } else if (arr[0] === "analysis" && arr[1]?.length) {
                      arr[1]?.length &&
                        arr[1].forEach((analysis, index) => {
                          if (
                            !columns.some(
                              (obj) => obj.key === `analysis ${index + 1}`
                            )
                          ) {
                            columns.push({
                              title: "Analysis",
                              dataIndex: `analysis ${index + 1}`,
                              key: `analysis ${index + 1}`,
                              render: (text) => (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    width: "max-content",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={text}
                                >
                                  {text}
                                </p>
                              ),
                            });
                          }
                          data[key] = {
                            ...data[key],
                            key,
                            [`analysis ${index + 1}`]: analysis,
                          };
                        });
                      return;
                    } else if (arr[0] === "data") {
                      // if (["value", "volume"].includes(arr[1])) {
                      // eslint-disable-next-line
                      Object.entries(arr[1])?.length &&
                        Object.entries(arr[1])?.forEach((type) => {
                          if (
                            !columns.some((obj) => obj.key === `${type[0]}Unit`)
                          ) {
                            columns.push({
                              title: `Unit`,
                              dataIndex: `${type[0]}Unit`,
                              key: `${type[0]}Unit`,
                              render: (text) => (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    width: "max-content",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={text}
                                >
                                  {text}
                                </p>
                              ),
                            });
                          }
                          data[key] = {
                            ...data[key],
                            key,
                            [`${type[0]}Unit`]: type[1].unit,
                          };

                          if (
                            !columns.some(
                              (obj) => obj.key === `${type[0]}Metric`
                            )
                          ) {
                            columns.push({
                              title: `Metric`,
                              dataIndex: `${type[0]}Metric`,
                              key: `${type[0]}Metric`,
                              render: (text) => (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    width: "max-content",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={text}
                                >
                                  {text}
                                </p>
                              ),
                            });
                          }
                          data[key] = {
                            ...data[key],
                            key,
                            [`${type[0]}Metric`]: type[0],
                          };

                          // loop for year for both value and volume
                          // eslint-disable-next-line
                          Object.entries(type[1]?.data)?.length &&
                            Object.entries(type[1]?.data)?.map((year) => {
                              if (
                                !columns.some(
                                  (obj) => obj.key === `${type[0]}${year[0]}`
                                )
                              ) {
                                columns.push({
                                  title: `${year[0]}`,
                                  dataIndex: `${type[0]}${year[0]}`,
                                  key: `${type[0]}${year[0]}`,
                                  render: (text) => (
                                    <p
                                      style={{
                                        overflow: "hidden",
                                        width: "max-content",
                                        minWidth: "100px",
                                        maxWidth: "200px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                      title={text}
                                    >
                                      {text}
                                    </p>
                                  ),
                                });
                              }
                              data[key] = {
                                ...data[key],
                                key,
                                [`${type[0]}${year[0]}`]: year[1],
                              };
                            });
                        });
                      // }
                    } else if (arr[0] === "source" && arr[1]?.length) {
                      arr[1]?.length &&
                        arr[1].forEach((source, index) => {
                          if (
                            !columns.some(
                              (obj) => obj.key === `source ${index + 1}`
                            )
                          ) {
                            columns.push({
                              title: "Source",
                              dataIndex: `source ${index + 1}`,
                              key: `source ${index + 1}`,
                              render: (text) => (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    width: "max-content",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={text}
                                >
                                  {text}
                                </p>
                              ),
                            });
                          }

                          data[key] = {
                            ...data[key],
                            key,
                            [`source ${index + 1}`]: source,
                          };
                          //
                        });
                      return;
                    } else {
                      if (
                        !columns.some((obj) => obj.key === `${arr[0]}`) &&
                        arr[0] != "_id"
                      ) {
                        columns.push({
                          title: `${arr[0]}`,
                          dataIndex: `${arr[0]}`,
                          key: `${arr[0]}`,
                          render: (text) => (
                            <p
                              style={{
                                overflow: "hidden",
                                width: "max-content",
                                minWidth: "100px",
                                maxWidth: "200px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              title={text}
                            >
                              {text}
                            </p>
                          ),
                        });
                      }

                      data[key] = {
                        ...data[key],
                        key,
                        [`${arr[0]}`]: arr[1],
                      };
                    }
                  });
              });

            columns.push({
              title: "Action",
              key: "action",
              fixed: "right",
              render: (_, record) => (
                <Button
                  onClick={() => {
                    this.setState({
                      delete_graph_data_modal: true,
                      selected_graph_id: record._id,
                      delete_graph_type: template,
                    });
                  }}
                >
                  Delete
                </Button>
              ),
            });

            if (template == "waterfall") {
              this.setState({
                waterfall_graph_column: columns,
                waterfall_graph_data: data.length ? data : null,
              });
            } else {
              this.props.setGraphColumn(columns);
              this.props.setGraphData(data);
            }
          }
        })
        .catch((error) => {
          console.log("ERROR OCCURED GRAPH DATA", error);
          notification.error({
            message: "Info",
            description: "ERROR OCCURED WHILE FETCHING GRAPH DATA",
          });
        });
    }
  };

  deleteGraphData = async () => {
    const data = {
      parent_ids: [this.state.selected_graph_id],
      type:
        this.state.delete_graph_type == "indicator"
          ? "indicator"
          : "typedetail",
    };
    let content;
    if (this.state.selected_graph_id) {
      if (this.props.template_type == "indicator") {
        content = this.state.indicator_graph_data.map((array) =>
          array.filter((item) => item._id == this.state.selected_graph_id)
        );
        content = this.formatGraphData(content.flatMap((array) => array));
      } else {
        content = (
          this.state.delete_graph_type == "waterfall"
            ? this.state.waterfall_graph_data
            : this.props.edit_graph_data
        ).filter((item) => item._id == this.state.selected_graph_id);
      }
      const columnsData =
        this.state.delete_graph_type == "waterfall"
          ? this.state.waterfall_graph_column
          : this.props.edit_graph_column;

      let columns = [];
      let metrics = "";
      columnsData.forEach((item) => {
        if (item.key == "action") {
          return;
        }

        if (item.title == "Unit") {
          metrics = item.key.replace("Unit", "");
        }
        if (
          !columns.some((obj) => obj.value == item.key.replace(metrics, ""))
        ) {
          if (item.title == "Unit") {
            columns.push({
              label:
                item.key.replace(metrics, "")[0].toUpperCase() +
                item.key.replace(metrics, "").substr(1).split("_").join(" ") +
                "s",
              value: item.key.replace(metrics, "") + "s",
            });
          } else {
            columns.push({
              label:
                item.key.replace(metrics, "")[0].toUpperCase() +
                item.key.replace(metrics, "").substr(1).split("_").join(" "),
              value: item.key.replace(metrics, ""),
            });
          }
        }
      });

      if (this.props.template_type == "indicator") {
        columns.push({
          label: "Indicator",
          value: "indicator",
        });
      }

      const newData = [];
      content.forEach((obj) => {
        const unitKeys = Object.keys(obj).filter((key) => key.endsWith("Unit"));

        unitKeys.forEach((unitKey) => {
          const unit = obj[unitKey];
          const metricKey = `${unitKey.slice(0, -4)}Metric`;
          const metric = obj[metricKey];

          const newObj = {
            key: obj.key,
            Units: unit,
            Metric: metric,
          };

          Object.keys(obj).forEach((key) => {
            if (!key.endsWith("Unit") && !key.endsWith("Metric")) {
              const newKey = key.replace(metric, "").replace(unit, "");
              newObj[newKey] = obj[key];
            }
          });

          if (this.props.template_type == "indicator") {
            newObj["indicator"] =
              this.state.slide_details?.meta?.indicator_type;
          }

          newData.push(newObj);
        });
      });

      const indicators = this.props?.TOC_DATA?.config?.indicator;
      const indicatorType = this.state?.slide_details?.meta?.indicator_type;

      const indicatorIndex = indicators?.findIndex(
        (indicator) => indicator?.indicator_type === indicatorType
      );

      const deletedData = [
        {
          sheet:
            this.props.template_type == "indicator"
              ? `indicator${indicatorIndex + 1}`
              : "ME Data",
          columns,
          content: newData,
        },
      ];
      const settings = {
        fileName: "Deleted Data",
        extraLength: 0,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
      };

      let callback = function (sheet) {
        notification.success({
          type: "info",
          message: "Deleted Data downloaded successfully.",
        });
      };

      xlsx(deletedData, settings, callback);

      await axios({
        method: "DELETE",
        url: `${config.api.base_url}/api/editing/delete_doc`,
        data,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            notification.success({
              type: "success",
              message: "Data deleted successfully",
            });
            if (this.state.delete_graph_type == "waterfall") {
              const newData = this.state.waterfall_graph_data.filter(
                (item) => item._id != this.state.selected_graph_id
              );
              this.setState({
                waterfall_graph_data: newData,
              });
            } else {
              if (this.props.template_type == "indicator") {
                const newData = this.state.indicator_graph_data.map((array) =>
                  array.filter(
                    (item) => item._id != this.state.selected_graph_id
                  )
                );
                this.setState({
                  indicator_graph_data: newData,
                });
              } else {
                const newData = this.props.edit_graph_data.filter(
                  (item) => item._id != this.state.selected_graph_id
                );
                this.props.setGraphData(newData);
              }
            }
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        });
    } else {
      notification.error({
        type: "error",
        message: "Error occured. Contact Tech.",
      });
    }
    this.setState({
      // selected_graph_id: null,
      delete_graph_data_modal: false,
    });
  };

  getdefaultSlideData = (slide, hub_id, template) => {
    this.props.setShowGraphVal(
      slide.meta.showGraphVal ? slide.meta.showGraphVal : false
    );
    localStorage.setItem("location", "Executive Summary & Key Findings");
    let body = {
      hub_id: hub_id,
      obj: {
        title: slide.title,
        dimensions: slide.dimensions,
        meta: {
          ...slide.meta,
        },
      },
    };
    this.props.setDimensions(slide.dimensions || null);
    axios
      .post(`${URL}/api/getSpecificData`, body)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            slide_templates: [template],
            slideData: response.data,
            slide_details: slide,
            hub_id: hub_id,
            defaultSelectedTocElement: [response.data.section_id],
            loading: false,
          });
          this.props.setTemplateType(response.data.template);
          this.props.setExecSummaryData(response.data.data.blocks);
          this.props.setParentId(response.data.data.parent_id);
          this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
            response.data.data.current_stage
              ? response.data.data.current_stage
              : "INITIAL"
          );
          this.props.setDraftStage(
            response.data.data.current_stage
              ? response.data.data.current_stage
              : "INITIAL"
          );
          this.props.setCacheId(
            response.data.hasOwnProperty("cacheId")
              ? JSON.parse(response.data.cacheId)
              : ""
          );
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  companyProfileApi = () => {
    this.props.setInitialDraftVersionsLoading(true);
    setTimeout(() => {
      axios({
        method: "POST",
        url: `${config.api.base_url}/api/editing/cpData`,
        data: {
          type: this.props.companyType,
          hub_id: this.state.slide_details.hub_id,
          dimensions: this.state.slide_details.dimensions,
          versions: this.props.ACTIVE_TAB,
          cacheId: this.props.cache_id,
          meta: {
            full_data: this.props.fetch_full_data,
          },
        },
      })
        .then((response) => {
          this.props.setCompanyProfileDimensions(response.data.dimensions);
          this.props.setCompanyProfileData(response.data.data);
          this.props.setInitialDraftVersionsLoading(false);
        })
        .catch((error) => {
          this.props.setInitialDraftVersionsLoading(false);
        });
    }, 1000);
  };

  getDraftVersionsAndFetchFirstDraft = () => {
    let template_type =
      this.props.template_type === "regulatoryFramework2"
        ? "regulatoryFramework"
        : this.props.template_type;
    axios
      .get(
        `${config.api.base_url}/api/editing/drafts?parent_id=${this.props.parent_id}&type=${template_type}&list=true`
      )
      .then((response) => {
        this.props.setInitialDraftVersionsLoading(false);
        if (response.status === 200 || response.status === 201) {
          this.props.setDraftVersions(response.data);
          if (!response.data.user_edit_access) {
            this.props.handleSwitchchange(false);
            this.setState({
              drafts_access_warning: true,
            });
            this.props.setSwitchDisable(true);
          }
          if (response.data.versions && !response.data.versions.length) {
            this.props.setNoDraftsFound(true);
            this.props.setDraftStage("NONE");
            this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
              response.data.stage ? response.data.stage : "INITIAL"
            );
          } else if (response.data.versions && response.data.versions.length) {
            if (
              response.data.versions[0].hasOwnProperty("email_id") &&
              this.props.USER_DATA.obj.email_id !==
                response.data.versions[0].email_id
            ) {
              this.setState({
                drafts_different_user_warning: true,
                draft_versions_email_id: response.data.versions[0].email_id,
              });
            }

            this.props.setNoDraftsFound(false);
            this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
              response.data.stage ? response.data.stage : "INITIAL"
            );
            this.props.setDraftStage(
              response.data.stage ? response.data.stage : "INITIAL"
            );

            return this.props.getSpecificDraftApi(
              response.data.versions[0].draftId,
              this.props.parent_id,
              this.props.template_type
            );
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
        this.props.setInitialDraftVersionsLoading(false);
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data.message,
          });
        }
        this.props.setDraftVersions({});
      });
  };

  getSubArrayKeysmethod = (keys) => {
    let getSubArrayKeys = keys.map((item, i) => {
      if (item.children && item.children.length) {
        this.state.subArrayKeys.push(item.meta.section_id);
        // this.getSubArrayKeysmethod(item.children);
      }
    });
  };

  createRelationArray = (data) => {
    return data.map((item, i) => {
      if (item.children && item.children.length) {
        return {
          ...item,
          title: item.title,
          section_id: item.meta.section_id,
          children: this.createRelationArray(item.children),
        };
      } else {
        return {
          ...item,
          title: item.title,
          section_id: item.meta.section_id,
        };
      }
    });
  };

  findAndOpenitem = (relatedarr, findkeys, keys) => {
    relatedarr.forEach((item, i) => {
      if (item.title === findkeys[0]) {
        keys.push(item.section_id);
        if (item.children && item.children.length) {
          this.findAndOpenitem(item.children, findkeys.slice(1), keys);
        } else {
          this.changeslide(null, item, item.hub_id);
        }
      }
    });
  };

  AddOverviewSlideToToc = (SegmentationToc) => {
    return (
      SegmentationToc.children &&
      SegmentationToc.children.length &&
      SegmentationToc.children.map((item, i) => {
        if (item.children && item.children.length) {
          if (item.meta.hasOwnProperty("specificIntroductionIndex")) {
            item.children.splice(item.meta.specificIntroductionIndex, 0, {
              title: item.title,
              dimensions: item.dimensions,
              meta: {
                ...item.meta,
                section_id: item.meta.section_id,
                // section_id: Base64.encode(SegmentationToc.meta.type + "overview"),
              },
              hub_id: SegmentationToc.hub_id,
              children: [],
              depth: 1,
            });
          } else {
            item.children.unshift({
              title: item.title,
              dimensions: item.dimensions,
              meta: {
                ...item.meta,
                section_id: item.meta.section_id,
                // section_id: Base64.encode(SegmentationToc.meta.type + "overview"),
              },
              hub_id: SegmentationToc.hub_id,
              children: [],
              depth: 1,
            });
          }

          this.AddOverviewSlideToToc(item);
        }
      })
    );
  };

  getTreeNodes = (TOC_TREE) => {
    return (
      TOC_TREE &&
      TOC_TREE.map((item, i) => {
        if (item.children && item.children.length) {
          return (
            <SubMenu
              key={item.meta.section_id}
              title={item.title}
              style={{
                whiteSpace: "normal",
                minHeight: "40px",
                height: "auto",
              }}
            >
              {this.getTreeNodes(item.children)}
            </SubMenu>
          );
        }
        return (
          <Menu.Item
            key={item.meta.section_id}
            onClick={(e) => {
              this.changeslide(e, item, item.hub_id);
              if (
                ["typeoverview", "typedetail", "indicator"].includes(
                  item.meta.template
                )
              ) {
                this.props.setGraphColumn([]);
                this.props.setGraphData([]);
                this.setState({ indicator_graph_data: [] });
                this.props.setEditGraph(false);
              }

              if (["indicator"].includes(item.meta.template)) {
                this.setState({
                  selectedIndicator: item.meta.indicator_type,
                });
                this.props.setEditGraphConfig(false);
              }

              if (this.props.editGraphConfig && this.props.TOC_DATA == {}) {
                window.location.reload();
              }
            }}
            style={{ whiteSpace: "normal", minHeight: "40px", height: "auto" }}
          >
            {" "}
            {item.meta.template === "typeoverview" &&
            item.meta.toc_specific_heading
              ? item.meta.toc_specific_heading
              : item.title}
          </Menu.Item>
        );
      })
    );
  };

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.state.subArrayKeys.indexOf(latestOpenKey) === -1) {
      this.setState({
        openKeys: keys,
      });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  changeslide = (e, slide, hub_id) => {
    this.props.partialStateReset();
    if (slide && hub_id) {
      this.setState({
        loading: true,
        drawer: false,
        slide_details: slide,
        hub_id: hub_id,
        active_slide: slide.meta.section_id,
        defaultSelectedTocElement: [slide.meta.section_id],
      });
      this.props.setDimensions(slide.dimensions || null);
      this.props.setShowGraphVal(
        slide.meta.showGraphVal ? slide.meta.showGraphVal : false
      );
      let body = {
        hub_id: hub_id,
        obj: {
          hub_id: hub_id,
          title: slide.title,
          dimensions: slide.dimensions,
          meta: {
            ...slide.meta,
          },
        },
        sample: false,
      };
      const selectedTabId = slide.meta.section_id;
      const grandparents = [];
      let parent = null;

      const findParentAndGrandparents = (tree, ancestors = []) => {
        tree.forEach((node) => {
          const currentAncestors = [...ancestors, node];

          if (node.children && node.children.length) {
            if (
              node.children.some(
                (child) => child.meta.section_id === selectedTabId
              )
            ) {
              parent = node;
              grandparents.push(...currentAncestors.slice(0, -1)); // Add all ancestors except the last one (parent)
            } else {
              findParentAndGrandparents(node.children, currentAncestors);
            }
          }
        });
      };

      findParentAndGrandparents(this.state.data);

      const grandparentsNames = grandparents
        .map((grandparent) => grandparent.title)
        .join(" - ");
      // Format the final string with hyphens
      const finalString = `${
        grandparentsNames ? grandparentsNames + " - " : ""
      }${parent ? parent.title + " - " : ""}${slide.title}`;

      localStorage.setItem("location", finalString);

      axios
        .post(`${URL}/api/getSpecificData`, body)
        .then((response) => {
          if (response.status === 200) {
            //----------Set Response Data-----------//
            if (
              slide.meta.template === "overview" &&
              slide.meta.typeValue === "introduction"
            ) {
              this.setState({
                slide_templates: ["introduction"],
                slideData: response.data,
                template_slide_name: response.data.template,
                template_title: response.data.title,
                loading: false,
              });
            } else {
              this.setState({
                slide_templates:
                  response.data.template === "global_data_pack"
                    ? response.data.data &&
                      response.data.data.map((item) => {
                        return item.multitemplate_names.map(
                          (item) => item.template_name
                        );
                      })
                    : response.data.data &&
                      response.data.data.multitemplate_names &&
                      response.data.data.multitemplate_names.map(
                        (item) => item.template_name
                      ),

                slideData: response.data,
                template_slide_name: response.data.template,
                template_title: response.data.title,
                loading: false,
              });
            }

            this.props.setTemplateType(response.data.template);

            //-----------Editing Code--------//
            if (response.data.template.includes("regulatoryFramework")) {
              //Set Indicator Dropdown Options
              this.props.setIndicatorDropdownOptions(
                response.data.data[0].data
              );
              // Show First Indicator Object
              let first_object = response.data.data[0].data[0];
              this.props.setIndicatorObjectToShow(first_object);

              this.props.setDraftDataAnalysis(
                first_object.analysis && first_object.analysis.length
                  ? first_object.analysis.map((item) => item.text)
                  : []
              );

              // heading added
              this.props.setDraftDataHeading(
                first_object.hasOwnProperty("heading") === true
                  ? first_object.heading
                  : ""
              );

              this.props.setDraftDataSources(
                first_object.source && first_object.source.length
                  ? first_object.source.map((item) => item.text)
                  : []
              );

              this.props.setParentId(first_object.parent_id);
              this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
                first_object.current_stage
                  ? first_object.current_stage
                  : "INITIAL"
              );
              this.props.setDraftStage(
                first_object.current_stage
                  ? first_object.current_stage
                  : "INITIAL"
              );
              this.props.setCacheId(
                response.data.hasOwnProperty("cacheId")
                  ? JSON.parse(response.data.cacheId)
                  : ""
              );
            } else if (response.data.template.includes("executive_summary")) {
              this.props.setExecSummaryData(response.data.data.blocks);
              this.props.setParentId(response.data.data.parent_id);
              this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
              this.props.setDraftStage(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
              this.props.setCacheId(
                response.data.hasOwnProperty("cacheId")
                  ? JSON.parse(response.data.cacheId)
                  : ""
              );
            } else if (!response.data.template.includes("companyProfile")) {
              //For all other templates - indicator and segmentation
              //Find the first analysis object and fetch the parent_id and analysis.
              //There will only be one analysis inside tha data.
              let filtered =
                response.data.data &&
                response.data.data.multitemplate_names
                  ?.map((item) =>
                    item.config_data.filter(
                      (inner) => inner.type === "analysis"
                    )
                  )
                  .filter((e) => e.length);
              let analysis_arr =
                filtered && filtered.length ? filtered[0][0].data.analysis : [];
              let parent_id =
                filtered && filtered.length
                  ? filtered[0][0].data.meta.parent_id
                  : null;

              //Set the initial draft analysis and sources from initial getSpecificData call.
              this.props.setDraftDataAnalysis(
                analysis_arr && analysis_arr.length
                  ? analysis_arr.map((item) => item.text)
                  : []
              );
              this.props.setDraftDataSources(
                response.data.source && response.data.source.length
                  ? response.data.source.map((item) => item.text)
                  : []
              );

              // heading added
              this.props.setDraftDataHeading(
                response.data.hasOwnProperty("heading") === true
                  ? response.data.heading
                  : ""
              );

              this.props.setParentId(parent_id);
              this.props.setCacheId(
                response.data.hasOwnProperty("cacheId")
                  ? JSON.parse(response.data.cacheId)
                  : ""
              );
              this.props.setDefaultDraftStageFromGetSpecificDataAndDraftVersions(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
              this.props.setDraftStage(
                response.data.data.current_stage
                  ? response.data.data.current_stage
                  : "INITIAL"
              );
            }
          }
        })
        .catch((err) => {
          console.log(err, "ERR");
          this.setState({
            loading: false,
          });
        });
    }
  };

  showDrawer = () => {
    this.setState({
      drawer: true,
    });
  };

  handleExpand = (expandedKeys) => {
    let ancestors = [];
    if (expandedKeys && expandedKeys.length)
      ancestors = this.fetchAncestors(expandedKeys[expandedKeys.length - 1]);
    this.setState({
      expandedKeys: ancestors,
    });
  };

  setDrawer = (menuOpend) => {
    this.setState({
      drawer: menuOpend,
    });
  };

  hidePublishedDrawer = () => {
    this.props.setPublishedDrawer(false);
  };

  hideDraftDrawer = () => {
    this.props.setDraftDrawer(false);
  };

  // Draft Version Modal
  triggerSpecificDraftModal = (draftId) => {
    this.setState({
      fetch_specific_draft_warning: true,
      draftId,
    });
  };

  continueFetchSpecificDraft = () => {
    this.props.setDraftDrawer(false);
    this.setState(
      {
        fetch_specific_draft_warning: false,
      },
      () =>
        this.props.getSpecificDraftApi(
          this.state.draftId,
          this.props.parent_id,
          this.props.template_type
        )
    );
  };

  cancelFetchSpecificDraft = () => {
    this.props.setDraftDrawer(false);
    this.setState({
      fetch_specific_draft_warning: false,
    });
  };

  // Published Version Modal
  triggerSpecificPublishedModal = (publishedId) => {
    this.setState({
      fetch_specific_published_warning: true,
      publishedId,
    });
  };

  continueFetchSpecificPublished = () => {
    this.props.setPublishedDrawer(false);
    this.setState(
      {
        fetch_specific_published_warning: false,
      },
      () =>
        this.props.getSpecificPublishedVersionApi(
          this.state.publishedId,
          this.props.template_type
        )
    );
  };

  cancelFetchSpecificPublished = () => {
    this.props.setPublishedDrawer(false);
    this.setState({
      fetch_specific_published_warning: false,
    });
  };

  handleDfferentUserDraftAccessCancel = () => {
    this.setState({
      drafts_different_user_warning: false,
    });
    this.props.handleSwitchchange(false);
  };

  render() {
    //If slide is an array that means it has templates like -> ['1001', '1002'], which is the newer version of templates.
    //If slide is not an array that means it has templates name in string like -> 'companyprofile1'.
    let editingFlag = this.state.slideData?.meta?.hasOwnProperty("editing")
      ? this.state.slideData.meta.editing
      : true;
    this.props.slideEditFlag(editingFlag);
    const Components = Array.isArray(this.state.slide_templates)
      ? this.state.template_slide_name === "global_data_pack"
        ? this.state.slide_templates
            .flat()
            .map((item) => componentMapping[item])
        : this.state.slide_templates.map((item) => componentMapping[item])
      : componentMapping[this.state.template_slide_name];

    return (
      <>
        <Spin tip="Loading..." spinning={this.state.loading}>
          <InterFaceStyleComponent>
            {window.screen.width > 960 ? null : (
              <div className="drawer">
                <MenuUnfoldOutlined
                  onClick={this.showDrawer}
                  style={{ paddingTop: "50px" }}
                />
                {this.state.slectedTocElement}x
              </div>
            )}
            <div className="content">
              {window.screen.width > 960 ? (
                <div className="leftColumn sideNav">
                  <Button
                    onClick={() => {
                      //Go Back
                      this.props.setSearchParams(false);

                      this.props.history.push(`/reports/search`);
                    }}
                    style={{ marginLeft: "1.2rem", marginBottom: "1rem" }}
                  >
                    Go Back <ArrowLeftOutlined />
                  </Button>
                  <h4>Contents</h4>

                  {/* <Collapse accordion expandIconPosition={"right"}> */}
                  <Menu
                    mode="inline"
                    openKeys={this.state.openKeys}
                    // need to get the title of the selected element
                    // on open change i want key and title
                    // defaultOpenKeys={this.state.openKeys}
                    onOpenChange={this.onOpenChange}
                    selectedKeys={this.state.defaultSelectedTocElement}
                  >
                    {this.getTreeNodes(this.state.data)}
                  </Menu>
                  {/* </Collapse>  */}
                </div>
              ) : (
                <Drawer
                  width="90%"
                  placement="left"
                  closable={true}
                  onClose={() => this.setDrawer(false)}
                  visible={this.state.drawer}
                >
                  <h4>Contents</h4>
                  {/* <Collapse accordion expandIconPosition={"right"}> */}
                  <Menu
                    mode="inline"
                    openKeys={this.state.openKeys}
                    //open by default
                    // defaultOpenKeys={this.state.openKeys}
                    onOpenChange={this.onOpenChange}
                    selectedKeys={this.state.defaultSelectedTocElement}
                  >
                    {this.getTreeNodes(this.state.data)}
                  </Menu>
                  {/* </Collapse> */}
                  <Button className="customize-report-button drawerbtns">
                    Customize the Report
                  </Button>
                  <Button className="data-charts-button drawerbtns">
                    Data & Charts
                  </Button>
                  <Button danger className="drawerbtns">
                    Purchase Report
                  </Button>
                  <Button type="primary" className="drawerbtns">
                    Purchases
                  </Button>
                  <Button className="faq-btn drawerbtns">
                    Frequently Asked Questions
                  </Button>
                </Drawer>
              )}
              {["typeoverview", "typedetail", "indicator"].includes(
                this.props?.template_type
              ) && this.props?.edit_graph ? (
                this.props.edit_graph_data.length ? (
                  <div className="rightColumn">
                    {this.state.indicator_graph_data?.length ? (
                      this.state.indicator_graph_data?.map((_item, index) => (
                        <>
                          <h3
                            style={{
                              margin: "50px 30px 20px",
                            }}
                          >
                            Graph No {index + 1}
                          </h3>

                          <Table
                            style={{
                              width: "95%",
                              overflow: "auto",
                              margin: "20px auto",
                              textTransform: "capitalize",
                            }}
                            columns={this.props.edit_graph_column}
                            dataSource={this.formatGraphData(
                              this.state.indicator_graph_data[index]
                            )}
                          />
                        </>
                      ))
                    ) : (
                      <>
                        <Table
                          style={{
                            width: "95%",
                            overflow: "auto",
                            margin: "20px auto",
                            textTransform: "capitalize",
                          }}
                          columns={this.props.edit_graph_column}
                          dataSource={this.props.edit_graph_data}
                        />
                        {this.props.template_type == "typedetail" &&
                          (this.state.waterfall_graph_data?.length ? (
                            <>
                              <h2
                                style={{
                                  margin: "50px 0 20px 50px",
                                }}
                              >
                                Waterfall Graph
                              </h2>
                              <Table
                                style={{
                                  width: "95%",
                                  overflow: "auto",
                                  margin: "20px auto",
                                  textTransform: "capitalize",
                                }}
                                columns={this.state.waterfall_graph_column}
                                dataSource={this.state.waterfall_graph_data}
                              />
                            </>
                          ) : (
                            <h2
                              style={{
                                margin: "50px",
                              }}
                            >
                              {this.state.waterfall_graph_data &&
                              !this.state.waterfall_graph_data?.length
                                ? "Loading..."
                                : "No Data Found in Waterfall Graph"}
                            </h2>
                          ))}
                      </>
                    )}
                  </div>
                ) : (
                  <h2
                    style={{
                      margin: "50px",
                    }}
                  >
                    {!this.props.edit_graph_data.length
                      ? "Loading..."
                      : "No Data Found"}
                  </h2>
                )
              ) : this.props?.editGraphConfig &&
                ["indicator"].includes(this.props?.template_type) ? (
                <GraphConfig selectedIndicator={this.state.selectedIndicator} />
              ) : (
                <div className="rightColumn respectiveContent">
                  {Array.isArray(Components) ? (
                    Components.map((Component, idx) => {
                      return (
                        <Component
                          fromInterface={this.state.fromInterface}
                          data={this.state.slideData}
                          template={this.state.template_slide_name}
                          template_title={this.state.template_title}
                          idx={idx}
                          edit_mode={this.props.edit_mode}
                          draft_drawer_visible={this.props.draft_drawer_visible}
                          published_drawer_visible={
                            this.props.published_drawer_visible
                          }
                          handleSwitchchange={this.props.handleSwitchchange}
                          slide_details={this.state.slide_details}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Components
                        fromInterface={this.state.fromInterface}
                        data={this.state.slideData}
                        template={this.state.template_slide_name}
                        template_title={this.state.template_title}
                        edit_mode={this.props.edit_mode}
                        draft_drawer_visible={this.props.draft_drawer_visible}
                        published_drawer_visible={
                          this.props.published_drawer_visible
                        }
                        handleSwitchchange={this.props.handleSwitchchange}
                        slide_details={this.state.slide_details}
                      />
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </InterFaceStyleComponent>

          <Drawer
            title="Draft Versions"
            closable={false}
            onClose={this.hideDraftDrawer}
            visible={this.props.DRAFT_DRAWER_VISIBLE}
            width="320"
          >
            {this.props.draft_versions_loading ? (
              <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
            ) : this.props.draft_versions &&
              Object.keys(this.props.draft_versions).length &&
              this.props.draft_versions.versions &&
              this.props.draft_versions.versions.length ? (
              this.props.draft_versions.versions.map((item) => {
                return (
                  <div
                    style={{
                      display: "inlineFlex",
                      borderBottom: "1px solid grey",
                      padding: "16px 24px",
                    }}
                  >
                    <p style={{ marginBottom: "0" }}>
                      <EditOutlined /> {item.name}
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "75%", margin: "0" }}>
                        <ClockCircleOutlined />{" "}
                        {new Date(item.date).toLocaleString()}
                      </p>
                      <Button
                        type="link"
                        style={{ padding: "0", width: "25%" }}
                        onClick={() =>
                          this.triggerSpecificDraftModal(item.draftId)
                        }
                      >
                        <ReloadOutlined /> Load
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p style={{ padding: "16px 24px" }}>NO DRAFTS FOUND</p>
            )}
          </Drawer>

          <Drawer
            title="Published Versions"
            placement={this.props.PLACEMENT}
            closable={false}
            onClose={this.hidePublishedDrawer}
            visible={this.props.PUBLISHED_DRAWER_VISIBLE}
            width="320"
          >
            {this.props.published_versions_loading ? (
              <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
            ) : this.props.published_versions &&
              this.props.published_versions.length ? (
              this.props.published_versions.map((item) => {
                return (
                  <div
                    style={{
                      display: "inlineFlex",
                      borderBottom: "1px solid grey",
                      padding: "16px 24px",
                    }}
                  >
                    <p style={{ marginBottom: "0" }}>
                      <EditOutlined /> {item.name}
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "75%", margin: "0" }}>
                        <ClockCircleOutlined />{" "}
                        {new Date(item.date).toLocaleString()}
                      </p>
                      <Button
                        type="link"
                        style={{ padding: "0", width: "25%" }}
                        onClick={() =>
                          this.triggerSpecificPublishedModal(item.publish_id)
                        }
                      >
                        <ReloadOutlined /> Load
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p style={{ padding: "16px 24px" }}>
                NO PUBLISHED VERSIONS FOUND
              </p>
            )}
          </Drawer>

          <Modal
            title="Notification"
            visible={this.state.fetch_specific_draft_warning}
            onCancel={this.cancelFetchSpecificDraft}
            onOk={this.continueFetchSpecificDraft}
            okText="Continue"
            cancelText="Cancel"
          >
            You are loading a previous version of this draft. Unsaved changes
            will be lost. Continue?
          </Modal>

          <Modal
            title="Notification"
            visible={this.state.fetch_specific_published_warning}
            onCancel={this.cancelFetchSpecificPublished}
            onOk={this.continueFetchSpecificPublished}
            okText="Continue"
            cancelText="Cancel"
          >
            You are loading a previous version of this published version.
            Unsaved changes will be lost. Continue?
          </Modal>

          <Modal
            title="Warning"
            centered
            visible={this.state.published_access_warning}
            footer={null}
            onOk={() =>
              this.setState({
                published_access_warning: false,
              })
            }
            onCancel={() =>
              this.setState({
                published_access_warning: false,
              })
            }
          >
            You don't have access to edit.
          </Modal>

          <Modal
            title="Warning"
            centered
            visible={this.state.drafts_access_warning}
            footer={null}
            onOk={() =>
              this.setState({
                drafts_access_warning: false,
              })
            }
            onCancel={() =>
              this.setState({
                drafts_access_warning: false,
              })
            }
          >
            The current draft is in this {this.props.draft_stage} stage, you
            don't have access to edit in this stage.
          </Modal>

          <Modal
            title="Warning"
            centered
            visible={this.state.drafts_different_user_warning}
            okText="Continue"
            cancelText="Cancel"
            onOk={() =>
              this.setState({
                drafts_different_user_warning: false,
              })
            }
            onCancel={this.handleDfferentUserDraftAccessCancel}
          >
            This draft is being worked on by{" "}
            {this.state.draft_versions_email_id}. Do you want to continue?
          </Modal>
        </Spin>

        <Modal
          title="Notification"
          visible={this.state.delete_graph_data_modal}
          onCancel={() =>
            this.setState({
              delete_graph_data_modal: false,
            })
          }
          onOk={() => {
            this.deleteGraphData();
          }}
          okText="Continue"
          cancelText="Cancel"
          footer={[
            <p
              style={{
                textAlign: "left",
              }}
            >
              Type DELETE to continue.
            </p>,
            <Input
              style={{
                marginBottom: "10px",
              }}
              type="text"
              onInput={(e) => {
                if (e.target.value.trim() === "DELETE") {
                  this.setState({
                    enable_delete_leaf_slide_btn: true,
                  });
                } else {
                  this.setState({
                    enable_delete_leaf_slide_btn: false,
                  });
                }
              }}
            />,
            <Button
              key="back"
              onClick={() =>
                this.setState({
                  delete_graph_data_modal: false,
                })
              }
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              danger
              // loading={this.state.draft_publish_loader}
              onClick={() => this.deleteGraphData()}
              disabled={!this.state.enable_delete_leaf_slide_btn}
            >
              Delete
            </Button>,
          ]}
        >
          Once you delete this slide, the analysis and Drafts will be lost. Are
          you sure you want to delete this slide?
        </Modal>
      </>
    );
  }
}

function mapStateToPros(state) {
  return {
    PLACEMENT: state.PLACEMENT,

    PUBLISHED_DRAWER_VISIBLE: state.PUBLISHED_DRAWER_VISIBLE,
    DRAFT_DRAWER_VISIBLE: state.DRAFT_DRAWER_VISIBLE,
    draft_versions: state.draft_versions,
    published_versions: state.published_versions,
    ACTIVE_TAB: state.ACTIVE_TAB,
    draft_stage: state.draft_stage,
    no_drafts_found: state.no_drafts_found,
    USER_DATA: state.USER_DATA,
    draft_versions_loading: state.draft_versions_loading,
    published_versions_loading: state.published_versions_loading,
    parent_id: state.parent_id,
    template_type: state.template_type,
    selected_indicator_heading: state.selected_indicator_heading,
    cache_id: state.cache_id,
    companyType: state.companyType,
    update_company_profile_data: state.update_company_profile_data,
    fetch_full_data: state.fetch_full_data,
    edit_graph: state.edit_graph,
    edit_graph_column: state.edit_graph_column,
    edit_graph_data: state.edit_graph_data,
    edit_graph: state.edit_graph,
    TOC_DATA: state.TOC_DATA,
    editGraphConfig: state.editGraphConfig,
  };
}

const mapDispatchToProps = {
  setTocData,
  setTocRawData,
  setSelectedCustomSlideData,
  setShowGraphVal,
  setDraftDrawer,
  setPublishedDrawer,
  setDraftVersions,
  setDraftStage,
  setNoDraftsFound,
  setSpecificDraftLoading,
  setDefaultDraftStageFromGetSpecificDataAndDraftVersions,
  setDraftDataAnalysis,
  setDraftDataSources,
  setActiveTab,
  setSwitchDisable,
  setSelectedIndicatorHeading,
  setIndicatorObjectToShow,
  setParentId,
  setCacheId,
  setTemplateType,
  setDraftId,
  setDraftDataHeading,
  setInitialDraftVersionsLoading,
  setIndicatorDropdownOptions,
  setCompanyProfileData,
  setCompanyProfileDimensions,
  setCompanyType,
  setCharacterCountError,
  setExecSummaryData,
  getSpecificDraftApi,
  getSpecificPublishedVersionApi,
  partialStateReset,
  setDimensions,
  setGraphColumn,
  setGraphData,
  setEditGraph,
  setEditGraphConfig,
};

export default withWindowSizeHOC(
  withRouter(connect(mapStateToPros, mapDispatchToProps)(Interface))
);
