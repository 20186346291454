import React from "react";

function CustomTempSeven() {
  const [limitConsumed, setLimitConsumed] = React.useState(0);
  const [limit] = React.useState(900);

  return <div>CustomTempSeven</div>;
}

export default CustomTempSeven;
