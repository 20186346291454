import React, { Component } from "react";
import ReactFC from "fusion_modules/fusioncharts-suite-xt-master/integrations/react/js/react-fusioncharts";
import FusionCharts from "fusion_modules/fusioncharts-suite-xt-master/addlicense";
import Charts from "fusion_modules/fusioncharts-suite-xt-master/js/fusioncharts.charts";
import FusionTheme from "fusion_modules/fusioncharts-suite-xt-master/js/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

export default class ScatterChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skipLabelList: [],
      reportName: this.props.reportName,
      data: this.props.data || [
        {
          label: "Company 1",
          x_axis: 3.5,
          y_axis: 2.5,
        },
        {
          label: "Company 2",
          x_axis: 4,
          y_axis: 3,
        },
        {
          label: "Company 3",
          x_axis: 2.5,
          y_axis: 3.5,
        },
        {
          label: "Company 4",
          x_axis: 3,
          y_axis: 4,
        },
        {
          label: "Company 5",
          x_axis: 3.5,
          y_axis: 4.5,
        },
        {
          label: "Company 6",
          x_axis: 4.5,
          y_axis: 3.5,
        },
        {
          label: "Company 7",
          x_axis: 4,
          y_axis: 2.5,
        },
        {
          label: "Company 8",
          x_axis: 2.5,
          y_axis: 2,
        },
        {
          label: "Company 9",
          x_axis: 3.5,
          y_axis: 3.5,
        },
        {
          label: "Company 10",
          x_axis: 4.5,
          y_axis: 4.5,
        },
        {
          label: "Company 11",
          x_axis: 2.5,
          y_axis: 4,
        },
        {
          label: "Company 12",
          x_axis: 4,
          y_axis: 4,
        },
        {
          label: "Company 13",
          x_axis: 3,
          y_axis: 3,
        },
      ],
    };
  }

  getCategoryArray(min, max) {
    let arr = [];
    let startPoint = min;
    while (startPoint <= max) {
      arr.push({
        label: "",
        fontColor: "#000",
        showLabel: 0,
        x: startPoint,
        showverticalline: "1",
      });
      startPoint += 0.5;
    }

    // console.log("CATEGORIES ARRAY", arr);
    return arr;
  }

  render() {
    const colors = this.state.data.map(
      () =>
        `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, "0")}`
    );

    // Function to generate a random color
    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const chartConfigs = {
      type: "scatter",
      dataFormat: "json",
      width: "1000",
      height: "500",
      id: "scatter-chart-container",
      dataSource: {
        chart: {
          theme: "fusion",
          chartLeftMargin: 20,
          chartRightMargin: 20,
          chartTopMargin: 20,
          chartBottomMargin: 20,

          showBorder: 1,
          borderThickness: 2,
          borderColor: "#000",
          xAxisMinValue: 0,
          xAxisMaxValue: 5,
          yAxisMinValue: 0,
          yAxisMaxValue: 5,

          showYAxisValues: 0,
          showXAxisValues: 0,
          drawQuadrant: 1,
          quadrantLineColor: "#000",
          quadrantLineThickness: 1.5,
          quadrantLineAlpha: 50,

          showcanvasborder: 1,
          canvasBorderThickness: 1,
          canvasBorderColor: "#CCCCCC",
          canvasBorderAlpha: 100,

          numDivLines: 15,

          divLineColor: "#CCCCCC",
          divLineThickness: "1",
          divLineAlpha: "100",
          showLegend: 1,
          legendItemFontBold: 1,
          legendPosition: "right",
          interactiveLegend: 0,
          anchorRadius: 8,
          anchorSides: 0,
        },
        categories: [
          {
            verticalLineThickness: 1,
            verticalLineColor: "#CCCCCC",
            category: this.getCategoryArray(0, 5),
          },
        ],
        dataset: this.state.data.map((item) => {
          const color = getRandomColor();
          return {
            seriesName: item.label,
            color: color,
            data: [
              {
                x: item.x_axis,
                y: item.y_axis,
                toolText: `${item.label} (x: ${item.x_axis}, y: ${item.y_axis})`,
                showValue: 0,
                anchorBgColor: color,
                anchorBorderColor: color,
              },
            ],
          };
        }),
        annotations: {
          groups: [
            {
              items: [
                {
                  id: "high-price",
                  type: "text",
                  text: "High Price",
                  align: "center",
                  x: "405",
                  y: "20",
                  fontSize: "14",
                  bold: "1",
                  color: "#000",
                  bgColor: "#d3d3d3",
                  alpha: "100",
                },
                {
                  id: "premium",
                  type: "text",
                  text: "Premium",
                  align: "center",
                  x: "787",
                  y: "250",
                  fontSize: "14",
                  bold: "1",
                  color: "#000",
                  bgColor: "#d3d3d3",
                  alpha: "100",
                  rotateText: "right",
                },
                {
                  id: "low-price",
                  type: "text",
                  text: "Low Price",
                  align: "center",
                  x: "405",
                  y: "480",
                  fontSize: "14",
                  bold: "1",
                  color: "#000",
                  bgColor: "#d3d3d3",
                  alpha: "100",
                },
                {
                  id: "non-premium",
                  type: "text",
                  text: "Non-Premium",
                  align: "center",
                  x: "20",
                  y: "250",
                  fontSize: "14",
                  bold: "1",
                  color: "#000",
                  bgColor: "#d3d3d3",
                  alpha: "100",
                  rotateText: "left",
                },
              ],
            },
          ],
        },
      },
    };

    return (
      <div>
        <ReactFC {...chartConfigs} />
        {/* <button onClick={this.resetChart}>Click</button> */}
      </div>
    );
  }
}
