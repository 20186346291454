import React from "react";

import {
  NoGraphContainer,
  HiddenGraphContainer,
  GraphContainer,
} from "./graphs.styles";

import Bar from "./Bar";
import BarLine from "./BarLine";
import Line from "./Line";
import MultiSeriesBar from "./MultiSeriesBar";
import MultiSeriesColumn from "./MultiSeriesColumn";
import MultiSeriesline from "./MultiSeriesLine";
import Pie from "./Pie";
import StackedBar from "./StackedBar";
import StackedColumn from "./StackedColumn";
import Waterfall from "./Waterfall";
import ScatterChart from "./Scatter";
import Geography from "./Geography/Main";
import India from "./Geography/india";
import Vietnam from "./Geography/vietnam";
import Europe from "./Geography/europe";
import africa from "./Geography/africa";
import germany from "./Geography/germany";
import china from "./Geography/china";
import russia from "./Geography/russia";
import argentina from "./Geography/argentina";
import asia from "./Geography/asia";
import brazil from "./Geography/brazil";
import australia from "./Geography/australia";
import middleeast from "./Geography/middleeast";
import northamerica from "./Geography/northAmerica";
import southamerica from "./Geography/southAmerica";
import indonesia from "./Geography/indonesia";
import usa from "./Geography/usa";
var Base64 = require("js-base64").Base64;
const { v4: uuidv4 } = require("uuid");

export const graphsConfig = {
  bar: Bar,
  barline: BarLine,
  line: Line,
  groupedbar: MultiSeriesColumn,
  hgroupedbar: MultiSeriesBar,
  msline: MultiSeriesline,
  pie: Pie,
  scatter: ScatterChart,
  stackedColumn: StackedColumn,
  Hstackedbar: StackedBar,
  waterfall2d: Waterfall,
  geography: Geography,
};
const geograhyConfigs = {
  india: India,
  world: Geography,
  europe: Europe,
  africa: africa,
  germany: germany,
  china: china,
  vietnam: Vietnam,
  russia: russia,
  argentina: argentina,
  asia: asia,
  brazil: brazil,
  australia: australia,
  middleeast: middleeast,
  northamerica: northamerica,
  southamerica: southamerica,
  indonesia: indonesia,
  usa: usa,
};
class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataHidden: false,
      graphHidden: false,
      graphType: "bar",
      data: [{ label: "Venezuela", value: "290" }],
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props?.totalData?.dataHidden) {
      this.setState({
        dataHidden: this.props.totalData.dataHidden,
      });
    }
    if (this.props?.graphType) {
      this.setState({
        graphType: this.props.graphType,
      });
    }
    if (this.props?.data) {
      this.setState({
        graphHidden: this.props.data === "hidden" ? true : false,
        data: this.props.data,
      });
    }
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if (this.props?.totalData?.dataHidden) {
        this.setState({
          dataHidden: this.props.totalData.dataHidden,
        });
      }
      if (this.props?.graphType) {
        this.setState({
          graphType: this.props.graphType,
        });
      }
      if (this.props?.data) {
        this.setState({
          graphHidden: this.props.data === "hidden" ? true : false,
          data: this.props.data,
        });
      }
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const Graph =
      this.props?.graphType == "geography"
        ? geograhyConfigs[this.props?.graph_config?.geography_type]
        : graphsConfig[this.state.graphType];

    return (
      <>
        {Graph !== undefined && !this.state.loading ? (
          <>
            {this.state.graphHidden ? (
              <HiddenGraphContainer
                width={this.props.width}
                height={this.props.height}
              >
                <label>{this.props.headers}</label>
                <div>Graph Hidden From View</div>
              </HiddenGraphContainer>
            ) : (
              <GraphContainer
                width={this.props.width}
                height={this.props.height}
                id={(this.props.headers + "")
                  .toLowerCase()
                  .trim()
                  .replace(/ /g, "_")}
              >
                <Graph
                  id={Base64.encode(uuidv4())}
                  graphType={this.state.graphType}
                  fromInterface={this.props.fromInterface}
                  template={this.props.template}
                  template_title={this.props.template_title}
                  template_name={this.props.template_name}
                  dataHidden={this.state.dataHidden}
                  graphHidden={this.state.graphHidden}
                  data={this.state.data ? this.state.data : []}
                  meta={this.props.meta ? this.props.meta : {}}
                  graph_meta={
                    this.props.graph_meta ? this.props.graph_meta : {}
                  }
                  headers={this.props.headers}
                  footers={this.props.footers}
                  min_SYAxis={this.props.min_SYAxis}
                  height={this.props.height}
                  width={this.props.width}
                  graph_config={
                    this.props.graph_config ? this.props.graph_config : {}
                  }
                  allow_cagr={
                    this.props?.totalData?.hasOwnProperty("cagr_value")
                      ? true
                      : false
                  }
                  cagr_year={this.props?.totalData?.cagr_year}
                  cagr_value={this.props?.totalData?.cagr_value}
                  yAxisPercentage={
                    this.props.yAxisPercentage === true ? true : false
                  }
                  maxMin={
                    this.props?.totalData?.hasOwnProperty("MaxMin")
                      ? this.props?.totalData?.MaxMin
                      : false
                  }
                  decimalLimit={
                    this.props?.totalData?.hasOwnProperty("decimalLimit")
                      ? this.props?.totalData?.decimalLimit
                      : 0
                  }
                  value_percentage={this.props.value_percentage}
                  yHeadingMargin={
                    this.props.yHeadingMargin ? this.props.yHeadingMargin : 0
                  }
                  xHeadingMargin={
                    this.props.xHeadingMargin ? this.props.xHeadingMargin : 0
                  }
                  wrapHeadingWidth={
                    this.props.wrapHeadingWidth
                      ? this.props.wrapHeadingWidth
                      : 0
                  }
                  chartHeadingGap={
                    this.props.chartHeadingGap ? this.props.chartHeadingGap : 0
                  }
                  chartTopMargin={
                    this.props.chartTopMargin ? this.props.chartTopMargin : 0
                  }
                  chartBottomMargin={
                    this.props.chartBottomMargin
                      ? this.props.chartBottomMargin
                      : 0
                  }
                  chartLeftMargin={
                    this.props.chartLeftMargin ? this.props.chartLeftMargin : 0
                  }
                  chartRightMargin={
                    this.props.chartRightMargin
                      ? this.props.chartRightMargin
                      : 0
                  }
                  legends={true}
                  dynamiclegends={
                    this.props.dynamiclegends ? this.props.dynamiclegends : ""
                  }
                  legendsPositionDynamic={
                    this.props.legendsPositionDynamic
                      ? this.props.legendsPositionDynamic
                      : false
                  }
                  legendsXPosition={
                    this.props.legendsXPosition
                      ? this.props.legendsXPosition
                      : 0
                  }
                  legendsYPosition={
                    this.props.legendsYPosition
                      ? this.props.legendsYPosition
                      : 0
                  }
                  valueFontSize={
                    this.props.valueFontSize ? this.props.valueFontSize : ""
                  }
                  rotateValues={
                    this.props.rotateValues ? this.props.rotateValues : 0
                  }
                  dataType={this.props.dataType ? this.props.dataType : ""}
                  radius={this.props.radius ? this.props.radius : 85}
                  labels={this.props.labels === true ? true : false}
                />
              </GraphContainer>
            )}
          </>
        ) : (
          <NoGraphContainer width={this.props.width} height={this.props.height}>
            Graph Not Available
          </NoGraphContainer>
        )}
      </>
    );
  }
}

export default Graph;
