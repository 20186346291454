import React, { useState } from "react";
import BlurredSampleReportsStyleWrapper from "./BlurredSampleReports.style";
import { Button, Input, notification, Select, Table, Tag, Tooltip } from "antd";
import axios from "axios";
import config from "../../config/Api";
import loader from "../../images/spinner.gif";
import InterfaceHeader from "../InterfaceHeader";

const openNotification = (msg, type) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Data Deleted successful.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to delete data.",
      duration: 3.5,
      placement: "topRight",
    };
    notification.error(args);
  } else if (type) {
    args = {
      message: type,
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification[type](args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "topRight",
    };
    notification.warning(args);
  }
};

function BlurredSampleReports() {
  const [infoTabVis, setInfoTabVis] = React.useState(false);
  const [hubData, setHubData] = React.useState([]);
  const [hubId, setHubId] = useState(null);

  const [onGoingApiCall, setOnGoingApiCall] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [reportsData, setReportsData] = React.useState([]);
  const [filteredReportsData, setFilteredReportsData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const fullReportAuth =
    process.env.REACT_APP_STAGE === "TEST"
      ? "?AWSAccessKeyId=AKIAI4DOUQKI2SUWYJOA&Expires=1741683119&Signature=rgkLbpsYl5Hk9baSjDXQxw4aEkU%3D&response-content-type=application%2Fpdf"
      : "?AWSAccessKeyId=AKIAI4DOUQKI2SUWYJOA&Expires=1741683267&Signature=o1f%2FcXAAmyVcDXSWhfDaIGNsUD0%3D&response-content-type=application%2Fpdf";
  const getHubList = async () => {
    setLoading(true);
    setOnGoingApiCall(true);
    if (hubData.length == 0) {
      let full_access = false;
      await axios
        .get(`${config.api.base_url}/api/hubs/list?full_access=${full_access}`)
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
          setOnGoingApiCall(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
          setOnGoingApiCall(false);
        });
    }
  };

  React.useEffect(() => {
    getHubList();
  }, []);

  const handleHubChange = async (value) => {
    setHubId(value);
    await fetchReports(value);
  };

  const fetchReports = async (hub_id) => {
    if (hub_id) {
      setOnGoingApiCall(true);
      axios
        .get(`${config.api.base_url}/api/blurred-samples/report/${hub_id}`)
        .then((resp) => {
          setOnGoingApiCall(false);
          setReportsData(resp?.data?.data || []);
          openNotification("Reports fetched successfully.", "success");
        })
        .catch((error) => {
          setOnGoingApiCall(false);
          console.log("ERROR OCCURED WHILE FETCHING REPORTS", error);
          openNotification(
            "Unable to fetch report. Please try again.",
            "error"
          );
        });
    }
  };

  const handleReportSearch = (text) => {
    let temp = [...reportsData];
    temp = temp.filter((item) =>
      item.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredReportsData(temp);
    setSearchQuery(text);
  };

  const handleUnblurPagesChange = (data, value) => {
    if (value?.length > getLimits(data.report_page_numbers)) {
      openNotification("Limit reached!!", "warning");
      return;
    }
    let temp = [...reportsData];
    temp[data.key - 1]["unblur_pages"] = value;
    setReportsData(temp);
  };

  const handleUpdateReport = async (data) => {
    try {
      setLoading(true);
      setOnGoingApiCall(true);

      const update = {
        "blur_sample_config.unblur_pages": data.unblur_pages,
      };

      const updateData = await axios.post(
        `${config.api.base_url}/api/blurred-samples/report/${data.report_id}`,
        {
          update,
        }
      );

      openNotification(
        updateData?.response?.data?.message || "Report Updated successfully",
        "success"
      );
    } catch (error) {
      console.log("ERROR OCCURED IN handleUpdateReport", error);
      openNotification(
        error.response?.data?.message ||
          "Something went wrong. Please try again.",
        "error"
      );
    }
    setLoading(false);
    setOnGoingApiCall(false);
  };

  function getLimits(pdfLength) {
    if (pdfLength >= 500) {
      return 20;
    } else {
      return 25;
    }
  }

  const reportColumn = [
    {
      title: "Serial No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Report Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Total Pages",
      dataIndex: "report_page_numbers",
      key: "report_page_numbers",
    },
    {
      title: "Sample Size",
      dataIndex: "blurred_sample_size",
      key: "blurred_sample_size",
      render: (_, data) => {
        if (!data.blurred_sample_size) {
          return (
            <Tag color="orange">
              <Tooltip title="Sample not found ">NA</Tooltip>
            </Tag>
          );
        } else if (Number(data.blurred_sample_size) > 10) {
          return (
            <Tag color="red">
              <Tooltip title="Sample Size is larger then 10 MB ">
                {data.blurred_sample_size} MB
              </Tooltip>
            </Tag>
          );
        }
        return (
          <Tag color="green">
            <Tooltip
              title="Sample Size is Fine.
            ">
              {data.blurred_sample_size} MB
            </Tooltip>
          </Tag>
        );
      },
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
      render: (_, data) => getLimits(data.report_page_numbers),
    },
    {
      title: "Unblur Pages",
      dataIndex: "unblur_pages",
      key: "unblur_pages",
      render: (unblur_pages, row) => (
        <Select
          mode="tags"
          style={{
            width: "100%",
          }}
          type="number"
          title="Unblur Pages"
          placeholder="enter page no"
          value={unblur_pages}
          onChange={(value) => {
            handleUnblurPagesChange(row, value);
          }}
          onInputKeyDown={(e) => {
            // Allow only numbers and backspace/delete
            console.log(row, "---", e.key);
            const isValidKey = (key) => {
              const validKeys = /^[0-9]$/;
              return (
                validKeys.test(key) || key === "Backspace" || key === "Delete"
              );
            };

            if (!isValidKey(e.key)) {
              e.preventDefault();
            }
          }}
          options={
            unblur_pages?.length
              ? unblur_pages.map((item) => ({
                  label: item,
                  value: item,
                }))
              : []
          }
        />
      ),
    },
    {
      title: "Sample URL",
      dataIndex: "sample_url",
      key: "sample_url",
      render: (sample_url) => (
        <Button
          type={sample_url ? "link" : "ghost"}
          onClick={() => sample_url && window.open(sample_url, "_blank")}>
          {sample_url ? "View Sample" : "NA"}
        </Button>
      ),
    },
    {
      title: "Full Report URL",
      dataIndex: "report_url",
      key: "report_url",
      render: (sample_url) => (
        <Button
          type={sample_url ? "link" : "ghost"}
          onClick={() =>
            sample_url &&
            window.open(`${sample_url}${fullReportAuth}`, "_blank")
          }>
          {sample_url ? "View Report" : "NA"}
        </Button>
      ),
    },
    {
      title: "Action",
      dataIndex: "save",
      key: "save",
      render: (_, data) => (
        <Button
          disabled={
            onGoingApiCall ||
            !data?.unblur_pages ||
            data?.unblur_pages?.length === 0
          }
          type="primary"
          onClick={() => handleUpdateReport(data)}>
          Save
        </Button>
      ),
    },
  ];

  return (
    <>
      <InterfaceHeader />
      <BlurredSampleReportsStyleWrapper>
        <div className="wrapper">
          <div className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}>
              <h2
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                  margin: "20px 0 30px",
                }}>
                Blurred Sample Reports
              </h2>{" "}
              <div className="top-section-wrapper">
                <div className="dropdownContainer">
                  <label htmlFor="selectHub">Select Hub :</label>
                  <Select
                    disabled={onGoingApiCall}
                    style={{
                      display: "block",
                      width: "300px",
                    }}
                    id="selectHub"
                    placeholder="select"
                    value={hubId}
                    loading={loading}
                    maxLength=""
                    onChange={handleHubChange}
                    options={hubData.map((item) => ({
                      key: item.id,
                      label: item.name,
                      value: item.id,
                    }))}
                    autoFocus={true}
                  />
                </div>

                {hubId && reportsData?.length && (
                  <div className="dropdownContainer">
                    <label htmlFor="searchReport">Search Report :</label>
                    <Input
                      disabled={onGoingApiCall}
                      style={{
                        display: "block",
                        width: "300px",
                      }}
                      id="searchReport"
                      placeholder="Search"
                      value={searchQuery}
                      loading={loading}
                      onChange={(e) => handleReportSearch(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="contentWrapper">
                {hubId &&
                  (reportsData && !onGoingApiCall ? (
                    <div className="reports-container">
                      <Table
                        style={{
                          overflow: "auto",
                          margin: "20px auto",
                          textTransform: "capitalize",
                        }}
                        columns={reportColumn}
                        dataSource={(searchQuery
                          ? filteredReportsData
                          : reportsData
                        )?.map((item, index) => ({
                          ...item,
                          serial: index + 1,
                          key: index + 1,
                        }))}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "50px 0",
                      }}>
                      <img src={loader} alt="loading" />
                    </div>
                  ))}
              </div>
            </div>

            <div className={infoTabVis ? "infoContainer" : "info"}>
              <Button
                type="default"
                danger={infoTabVis}
                onClick={() => setInfoTabVis(!infoTabVis)}>
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div className="infoContent">
                  <b>
                    <h4>Delete Segmentation Data in Bulk.</h4>
                    <br />
                    <div>
                      <p>⏺ Select your hub.</p>
                      <p>
                        ⏺ Enter the dimensions of the report which you would
                        like to delete.
                      </p>
                      <p>
                        ⏺ Click on Count Results and verify the count with the
                        number of reports data you want to delete.
                      </p>
                      <p>
                        ⏺ Click Delete and then confirm to delete the data.
                      </p>
                      <p>
                        ⏺ A copy of deleted data will be downloaded on your
                        device.
                      </p>
                      <p>
                        ⏺ Deleted data can be uploaded back using Upload Data
                        button.
                      </p>
                      <br />
                      <h4>Steps to Reupload Deleted Segmentation Data.</h4>
                      <br />
                      <div>
                        <p>
                          ⏺ To upload the deleted data you can either upload
                          the ME data sheet from the Upload Sheet section or you
                          can Select Hub for which you want to upload the data.
                        </p>
                        <p>
                          ⏺ Select the JSON file and then click on Upload
                          button.
                        </p>
                      </div>
                      <hr></hr>
                      <h4>
                        <center>
                          Please contact Tech in case of any query.
                        </center>
                      </h4>
                    </div>
                  </b>
                </div>
              )}
            </div>
          </div>
        </div>
      </BlurredSampleReportsStyleWrapper>
    </>
  );
}

export default BlurredSampleReports;
