import React, { useState } from "react";
import { Row } from "./style";

import { Button } from "antd";
import DataModal from "./DataModal";
import { defaultAnalysisCustomSlide } from "../../../constants";
import CustomAnalysis from "../commonFunctions/CustomAnalysis";

function CustomTempThree(props) {
  console.log(props);
  const { data, editMode, onSave, saveLoading } = props;
  const { analysis, slideHeading, _id = null } = data;
  const [showDataModal, setShowDataModal] = useState(false);
  const [tempConfig, setTempConfig] = useState({
    slideHeading,

    analysis: analysis ? analysis : defaultAnalysisCustomSlide,
  });
  const [limitConsumed, setLimitConsumed] = useState(0);
  const [limit] = useState(3500);

  const addData = (data) => {
    setTempConfig((prev) => ({
      ...prev,
      graphs: { ...prev.graphs, data: data },
    }));
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}>
          <input
            placeholder="slide heading"
            style={{ width: "100%" }}
            value={tempConfig.slideHeading}
            onChange={(e) =>
              setTempConfig((p) => {
                return { ...p, slideHeading: e.target.value };
              })
            }
          />
        </div>

        <Row style={{ display: "flex", flexDirection: "column" }}>
          <input
            placeholder="Analysis heading"
            value={tempConfig.analysis.heading}
            onChange={(e) =>
              setTempConfig((p) => {
                return {
                  ...p,
                  analysis: {
                    ...p.analysis,
                    heading: e.target.value,
                  },
                };
              })
            }
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "30px"
            }}>
            <p>Analysis: </p>
            <p>
              Limit: {limitConsumed}/{limit}
            </p>
          </div>

          <CustomAnalysis
            trends={tempConfig.analysis.data}
            setTempConfig={setTempConfig}
            edit_mode={true}
            section_id={"data.section_id"}
            limit={limit}
            setLimitConsumed={setLimitConsumed}
          />
        </Row>
      </div>
      <div>
        {" "}
        <Button
          type="primary"
          loading={saveLoading}
          disabled={saveLoading || limitConsumed > limit}
          onClick={() => {
            onSave({
              id: editMode && _id ? _id : "new",
              finalData: {
                ...tempConfig,
                graphs: [tempConfig.graphs],
                analysis: tempConfig.analysis,
              },
            });
          }}>
          Save
        </Button>
      </div>
      {showDataModal && (
        <DataModal
          addData={addData}
          graphType={tempConfig.graphs.graphType || ""}
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
    </div>
  );
}

export default CustomTempThree;
